import { MapElementFactory } from "vue2-google-maps"

export default MapElementFactory({
    name: "directionsRenderer",

    ctr() {
        return window.google.maps.DirectionsRenderer;
    },

    events: [],

    mappedProps: {},

    props: {
        origin: { type: [Object,Array] },
        destination: { type: [Object,Array] },
        travelMode: { type: String }
    },

    afterCreate(directionsRenderer) {
        let directionsService = new window.google.maps.DirectionsService;
        let { origin, destination, travelMode } = this
        if (!origin || !destination || !travelMode) return

        directionsService.route({
                origin,
                destination,
                travelMode

        }).then((response) => {
            directionsRenderer.setDirections(response);
        })
    }
})
