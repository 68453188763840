<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DirectionsRenderer from "@/views/pages/rides/DirectionsRenderer";
// import axios from "axios";

export default {
  page: {
    title: "Standard Ride Details",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DirectionsRenderer },
  data() {
    return {
      // ride: null,
      title: "Premium Ride Details",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Premium Rides",
          href: "JavaScript:void(0)"
        },
        {
          text: "Premium Rides Details",
          active: true
        }
      ],
    }
  },
  async mounted() {
    // await axios.get('read-ride-by-id/' + this.$route.params.id, {
    //   headers: {
    //     'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
    //   }
    // }).then((res) => {
    //   this.ride = res.data.ride
    // }).catch((err) => {
    //   console.log(err)
    // })
      this.$store.dispatch('hourlyRides/getStandardRidesForDetail')


  },

  computed:{
    ride(){
      return this.$store.getters['hourlyRides/standardRideGetter'](this.$route.params.id)
    }
  },
  
  methods: {
    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },
    cancelStatus(status) {
      if (status === true) {
        return 'Yes'
      }
      else {
        return 'No'
      }
    },

  }

}

</script>

<template>
  <layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="row mt-4">
        <div class="col-md-4" style="text-align: center">
          <div class="card">
            <div class="card-body">
              <span>Start</span>
              <h5 class="fw-bold mt-2">
                <address>
                  <p><span>{{ ride.pick_up_address }}</span></p>
                </address>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="text-align: center">
          <div class="card">
            <div class="card-body">
              <span>End</span>
              <h5 class="fw-bold mt-2">
                <address>
                  <p style="margin-bottom: 0%"><span>{{ ride.drop_off_address }}</span></p>
                </address>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="text-align: center">
          <div class="card">
            <div class="card-body">
              <span>Travel</span>
              <h5 class="fw-bold mt-2">
                <p>
                  <span class="fw-bold">{{ ride.estimated_distance }}</span><span> miles</span>
                  <span class="fw-bold"> {{ (Math.round((ride.ride_duration / 60 / 60) * 100) / 100).toFixed(2)
                  }}</span><span>
                    hours</span>
                </p>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>Vehicle</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Car Model</th>
                      <td class="text-right">{{ ride.car_model }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Car Name</th>
                      <td class="text-right">{{ ride.car_name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Car Plate Number</th>
                      <td class="text-right">{{ ride.car_plate_number }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Car Type</th>
                      <td class="text-right">{{ ride.car_type }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Car Year</th>
                      <td class="text-right">{{ ride.car_year }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>Ride Detail</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Rider</th>
                      <td class="text-right">{{ ride.rider_name }} </td>
                    </tr>
                    <tr>
                      <th scope="row">Driver</th>
                      <td class="text-right">{{ ride.driver_name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Booking</th>
                      <td class="text-right">{{ convertDate(ride.createdAt) }}  {{ convertTime(ride.createdAt) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Pick Up</th>
                      <td class="text-right">{{ convertDate(ride.pick_up_at) }}  {{ convertTime(ride.pick_up_at) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Fare</th>
                      <td class="text-right">${{ ride.fare }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td class="text-right">{{ ride.status }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Canceled</th>
                      <td class="text-right">{{ cancelStatus(ride.canceled) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Canceled By</th>
                      <td class="text-right">{{ ride.canceledBy }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="row">

        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">
              <h4>Ride Timeline</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Scheduled</th>
                      <td class="text-right">{{ convertDate(ride.events.scheduled) }} 
                        {{ convertTime(ride.events.scheduled) }} </td>
                    </tr>
                    <tr>
                      <th scope="row">Driver On the way</th>
                      <td class="text-right">{{ convertDate(ride.events.driverOnTheWay) }} 
                        {{ convertTime(ride.events.driverOnTheWay) }} </td>
                    </tr>
                    <tr>
                      <th scope="row">Driver At Pickup Location</th>
                      <td class="text-right">{{ convertDate(ride.events.driverAtPickup) }} 
                        {{ convertTime(ride.events.driverAtPickup) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ride Started</th>
                      <td class="text-right">{{ convertDate(ride.events.rideStarted) }} 
                        {{ convertTime(ride.events.rideStarted) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ride Ended</th>
                      <td class="text-right">{{ convertDate(ride.events.rideEnded) }} 
                        {{ convertTime(ride.events.rideEnded) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ride Completed</th>
                      <td class="text-right">{{ convertDate(ride.events.rideCompleted) }} 
                        {{ convertTime(ride.events.rideCompleted) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h4>Invoice</h4>
            </div>
            <div class="card-body">
              <table id="data-table" class="table data-table">
                <tbody>
                  <tr>
                    <th><strong>Cancel Charges</strong></th>
                    <td>$ {{ ride.cancel_charges }}</td>
                  </tr>
                  <tr>
                    <th><strong>Base Fare</strong></th>
                    <td>$ {{ ride.receipt.base_fare }}</td>
                  </tr>
                  <tr>
                    <th><strong>Rydelinx Cut</strong></th>
                    <td>$ {{ ride.receipt.rydelinx_cut }}</td>
                  </tr>

                  <tr>
                    <th><strong>Tip</strong></th>
                    <td>$ {{ ride.receipt.tip }}</td>
                  </tr>
                  <tr style="border-top: solid grey 2px">
                    <th><strong>Total</strong></th>
                    <td>${{ ride.receipt.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <GmapMap :zoom="15"
                :center="{ lat: ride.pick_up_location.coordinates[1], lng: ride.pick_up_location.coordinates[0] }"
                style="width: 100%;height: 400px">
                <DirectionsRenderer travelMode="DRIVING"
                  :origin="{ lat: ride.pick_up_location.coordinates[1], lng: ride.pick_up_location.coordinates[0] }"
                  :destination="{ lat: ride.drop_off_location.coordinates[1], lng: ride.drop_off_location.coordinates[0] }" />
              </GmapMap>
            </div>
          </div>
        </div>

      </div>
    </div>


  </layout>
</template>
<style scoped>
.card-header {
  background-color: white !important;
}</style>